<template>
  <div>
    <a-modal
      v-draggable
      width="88%"
      :title="$t('服务管理.后巷屋订单管理.订单服务详情')"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-card :bordered="false">
        <!-- 条件搜索 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <!-- <a-form-item label="后巷屋订单id" prop="orderLanewayHousesId">
                  <a-input v-model="queryParam.orderLanewayHousesId" placeholder="请输入后巷屋订单id" allow-clear />
                </a-form-item> -->
                <a-form-item :label="$t('服务管理.后巷屋订单管理.服务状态')" prop="status">
                  <a-select placeholder="请选择状态" style="width: 100%" v-model="queryParam.status">
                    <!-- 0-未开始,1-待开始/待上传,2-待支付,3-已支付 -->
                    <a-select-option value="0"> 未开始 </a-select-option>
                    <a-select-option value="1"> 待开始 </a-select-option>
                    <a-select-option value="2"> 待支付 </a-select-option>
                    <a-select-option value="3"> 已支付 </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('服务管理.后巷屋订单管理.服务名称')" prop="name">
                  <a-input
                    v-model="queryParam.name"
                    :placeholder="$t('服务管理.后巷屋订单管理.请输入服务名称')"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <!-- <template v-if="advanced">
                <a-col :md="8" :sm="24">
                  <a-form-item label="服务价格" prop="money">
                    <a-input v-model="queryParam.money" placeholder="请输入服务价格" allow-clear />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="顺序" prop="sort">
                    <a-input v-model="queryParam.sort" placeholder="请输入顺序" allow-clear />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="支付时间" prop="payTime">
                    <a-date-picker
                      style="width: 100%"
                      v-model="queryParam.payTime"
                      format="YYYY-MM-DD HH:mm:ss"
                      allow-clear
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="支付金额" prop="payMoney">
                    <a-input v-model="queryParam.payMoney" placeholder="请输入支付金额" allow-clear />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="定金抵扣金额" prop="deductionMoney">
                    <a-input v-model="queryParam.deductionMoney" placeholder="请输入定金抵扣金额" allow-clear />
                  </a-form-item>
                </a-col>
              </template> -->
              <a-col :md="(!advanced && 8) || 24" :sm="24">
                <span
                  class="table-page-search-submitButtons"
                  :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
                >
                  <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"
                    ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                  >
                  <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                    {{ advanced ? '收起' : '展开' }}
                    <a-icon :type="advanced ? 'up' : 'down'" />
                  </a> -->
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- 操作 -->
        <!-- <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['order:lanewayHousesServe:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['order:lanewayHousesServe:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['order:lanewayHousesServe:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['order:lanewayHousesServe:export']">
            <a-icon type="download" />导出
          </a-button>
          <table-setting
            :style="{ float: 'right' }"
            :table-size.sync="tableSize"
            v-model="columns"
            :refresh-loading="loading"
            @refresh="getList"
          />
        </div> -->
        <!-- 增加修改 -->
        <create-form ref="createForm" @ok="getList" />
        <!-- 数据展示 -->
        <a-table
          :loading="loading"
          :size="tableSize"
          rowKey="id"
          :columns="columns"
          :data-source="list"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :pagination="false"
          :bordered="tableBordered"
          :scroll="{ x: '100%' }"
        >
          <span slot="payTime" slot-scope="text, record">
            {{ parseTime(record.payTime) }}
          </span>
          <span slot="type" slot-scope="text, record">
            {{ record.type == '0' ? '方案' : '施工' }}
          </span>
          <span slot="createTime" slot-scope="text, record">
            {{ parseTime(record.createTime) }}
          </span>
          <!-- 0-未开始,1-待开始/待上传,2-待支付,3-已支付 -->
          <template slot="status" slot-scope="text, record">
            <span v-if="record.status == 0">未开始</span>
            <span v-if="record.status == 1">待开始</span>
            <span v-if="record.status == 2">待支付</span>
            <span v-if="record.status == 3">已支付</span>
          </template>
          <!-- 0-未开始,1-待支付,2-线下支付待审核,3-线下支付,4-已支付 -->
          <template slot="payStatus" slot-scope="text, record">
            <span v-if="record.payStatus == 0">未开始</span>
            <span v-if="record.payStatus == 1">待支付</span>
            <span v-if="record.payStatus == 2">线下支付待审核</span>
            <span v-if="record.payStatus == 3">线下支付</span>
            <span v-if="record.payStatus == 4">已支付</span>
          </template>
          <span slot="operation" slot-scope="text, record">
            <a-divider type="vertical" v-hasPermi="['order:lanewayHousesServe:edit']" v-if="record.payStatus == 2" />
            <a
              @click="$refs.createForm.handleUpdate(record, undefined)"
              v-hasPermi="['order:lanewayHousesServe:edit']"
              v-if="record.payStatus == 2"
            >
              <a-icon type="form" />{{ $t('服务管理.后巷屋订单管理.审核') }}
            </a>
            <!-- <a-divider type="vertical" v-hasPermi="['order:lanewayHousesServe:remove']" />
            <a @click="handleDelete(record)" v-hasPermi="['order:lanewayHousesServe:remove']">
              <a-icon type="delete" />删除
            </a> -->
            <a-divider type="vertical" v-hasPermi="['order:lanewayHousesServe:edit']" />
            <a
              @click="$refs.baogaoUploadRefs.handleUpload(record, undefined)"
              v-hasPermi="['order:lanewayHousesServe:edit']"
            >
              <a-icon type="edit" />{{ $t('服务管理.后巷屋订单管理.上传报告') }}
            </a>
          </span>
        </a-table>
        <!-- 分页 -->
        <a-pagination
          class="ant-table-pagination"
          show-size-changer
          show-quick-jumper
          :current="queryParam.pageNum"
          :total="total"
          :page-size="queryParam.pageSize"
          :showTotal="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="changeSize"
        />
      </a-card>

      <!-- 修改弹框 -->
      <edit-form ref="createForm" @ok="getList" />

      <!-- 报告弹框 -->
      <baogao-modal ref="baogaoUploadRefs" @baogaoUploadSucc="handleBaogaoSucc" />
    </a-modal>
  </div>
</template>
<script>
import { pageLanewayHousesServe, listLanewayHousesServe, delLanewayHousesServe } from '@/api/order/lanewayHousesServe'
import EditForm from './EditForm.vue'
import { tableMixin } from '@/store/table-mixin'
import BaogaoModal from './Baogao-modal.vue'
// import vuedraggable from 'vuedraggable'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    data: {
      handler: function (newVal, oldVal) {
        console.log('tableData', newVal)
        this.list = newVal.records
        this.total = newVal.total
      },
      immediate: true,
    },
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  directives: {},
  data() {
    return {
      tableSize: 10,
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      //////////////////////
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        orderLanewayHousesId: null,
        name: null,
        type: null,
        money: null,
        sort: null,
        status: null,
        payStatus: null,
        payTime: null,
        payScreenshot: null,
        payMoney: null,
        deductionMoney: null,
        fileIds: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        // {
        //   title: '主键',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center',
        // },
        // {
        //   title: '后巷屋订单id',
        //   dataIndex: 'orderLanewayHousesId',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '服务名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
        {
          // ,0-方案,1-施工
          title: '服务类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
        {
          title: '服务价格',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
        // {
        //   title: '顺序',
        //   dataIndex: 'sort',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          // ,0-未开始,1-待开始/待上传,2-待支付,3-已支付
          title: '服务状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center',
          width: '120px',
        },
        {
          //,0-未开始,1-待支付,2-线下支付待审核,3-线下支付,4-已支付
          title: '支付状态',
          dataIndex: 'payStatus',
          scopedSlots: { customRender: 'payStatus' },
          ellipsis: true,
          align: 'center',
          width: '120px',
        },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          ellipsis: true,
          align: 'center',
          width: '160px',
        },
        // {
        //   title: '支付截图',
        //   dataIndex: 'payScreenshot',
        //   ellipsis: true,
        //   align: 'center',
        //   width: '120px',
        // },
        {
          title: '支付金额',
          dataIndex: 'payMoney',
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
        {
          title: '定金抵扣金额',
          dataIndex: 'deductionMoney',
          ellipsis: true,
          align: 'center',
          width: '140px',
        },
        // {
        //   title: '上传文件',
        //   dataIndex: 'fileIds',
        //   ellipsis: true,
        //   align: 'center',
        //   width: '180px',
        // },
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: '160px',
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center',
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          width: '200px',
          fixed: 'right',
        },
      ],
    }
  },
  components: {
    EditForm,
    BaogaoModal,
  },
  methods: {
    showModal(params) {
      this.visible = true
      if (params) {
        this.queryParam.orderLanewayHousesId = params
      }
    },

    handleOk(e) {
      this.ModalText = 'The modal will be closed after two seconds'
      this.confirmLoading = true
      setTimeout(() => {
        this.visible = false
        this.confirmLoading = false
      }, 2000)
    },
    handleCancel(e) {
      console.log('Clicked cancel button')
      this.visible = false
    },
    /** 查询后巷屋订单服务列表 */
    getList() {
      this.loading = true
      pageLanewayHousesServe(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      // this.queryParam = {
      //   orderLanewayHousesId: undefined,
      //   name: undefined,
      //   type: undefined,
      //   money: undefined,
      //   sort: undefined,
      //   status: undefined,
      //   payStatus: undefined,
      //   payTime: undefined,
      //   payScreenshot: undefined,
      //   payMoney: undefined,
      //   deductionMoney: undefined,
      //   fileIds: undefined,
      //   pageNum: 1,
      //   pageSize: 10,
      // }
      this.queryParam.status = null
      this.queryParam.name = null
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delLanewayHousesServe(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'order/orderLanewayHousesServe/export',
            {
              ...that.queryParam,
            },
            `后巷屋订单服务_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
    handleBaogaoSucc() {
      this.$emit('baogaosuccess')
    },
     updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
            {
          title: 'name',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
        {
          // ,0-方案,1-施工
          title: 'type',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
        {
          title: 'money',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
       
        {
          // ,0-未开始,1-待开始/待上传,2-待支付,3-已支付
          title: 'status',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center',
          width: '120px',
        },
        {
          //,0-未开始,1-待支付,2-线下支付待审核,3-线下支付,4-已支付
          title: 'payStatus',
          dataIndex: 'payStatus',
          scopedSlots: { customRender: 'payStatus' },
          ellipsis: true,
          align: 'center',
          width: '120px',
        },
        {
          title: 'payTime',
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          ellipsis: true,
          align: 'center',
          width: '160px',
        },
       
        {
          title: 'payMoney',
          dataIndex: 'payMoney',
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
        {
          title: 'deductionMoney',
          dataIndex: 'deductionMoney',
          ellipsis: true,
          align: 'center',
          width: '140px',
        },
       
        {
          title: 'createTime',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: '160px',
        },
        {
          title: 'operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          width: '200px',
          fixed: 'right',
        },
        ],
        'zh-CN': [
              
         {
          title: '服务名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
        {
          // ,0-方案,1-施工
          title: '服务类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
        {
          title: '服务价格',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
       
        {
          // ,0-未开始,1-待开始/待上传,2-待支付,3-已支付
          title: '服务状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center',
          width: '120px',
        },
        {
          //,0-未开始,1-待支付,2-线下支付待审核,3-线下支付,4-已支付
          title: '支付状态',
          dataIndex: 'payStatus',
          scopedSlots: { customRender: 'payStatus' },
          ellipsis: true,
          align: 'center',
          width: '120px',
        },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          ellipsis: true,
          align: 'center',
          width: '160px',
        },
       
        {
          title: '支付金额',
          dataIndex: 'payMoney',
          ellipsis: true,
          align: 'center',
          width: '100px',
        },
        {
          title: '定金抵扣金额',
          dataIndex: 'deductionMoney',
          ellipsis: true,
          align: 'center',
          width: '140px',
        },
       
        {
          title: '插入时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: '160px',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
          width: '200px',
          fixed: 'right',
        },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
